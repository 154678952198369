import { createContext, useState } from "react";
import React  from 'react';


const IssueContext = createContext({})

const IssueProvider = ({ children }) => {
    const [issue, setIssue] = useState()

    const issueContextData = {
        issue,
        setIssue
    }

    return (
        <IssueContext.Provider value={issueContextData} >
            {children}
        </IssueContext.Provider>
    )
}

export {IssueContext, IssueProvider}
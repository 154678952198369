import React from 'react';
import {
    MenuItem,
    TextField,
    Grid,
    Chip,
  } from "@mui/material";

function GridAgua({handler}, {edificio}) {
  return (
    <div>
        <Grid item xs={3}>
            <TextField
              label="Edifício"
              variant="outlined"
              value={edificio}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handler}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                  </div>
                ),
              }}
            >
                <MenuItem value={'FAL'}>FAL</MenuItem>
<MenuItem value={'C MAN OVINOS'}>C MAN OVINOS</MenuItem>
<MenuItem value={'ENSAIOS METAB'}>ENSAIOS METAB</MenuItem>
<MenuItem value={'FAAL VIV PJ'}>FAAL VIV PJ</MenuItem>
<MenuItem value={'FAL ADMINISTR'}>FAL ADMINISTR</MenuItem>
<MenuItem value={'FAL ALMOX'}>FAL ALMOX</MenuItem>
<MenuItem value={'FAL ALMOX VIVEIRO'}>FAL ALMOX VIVEIRO</MenuItem>
<MenuItem value={'FAL C. VEG HORTA'}>FAL C. VEG HORTA</MenuItem>
<MenuItem value={'FAL CASA BOMBAS'}>FAL CASA BOMBAS</MenuItem>
<MenuItem value={'FAL CONF OVINOS II'}>FAL CONF OVINOS II</MenuItem>
<MenuItem value={'FAL DEP HORTA'}>FAL DEP HORTA</MenuItem>
<MenuItem value={'FAL DEP LACTICÍNIO'}>FAL DEP LACTICÍNIO</MenuItem>
<MenuItem value={'FAL DEP SEMENTES'}>FAL DEP SEMENTES</MenuItem>
<MenuItem value={'FAL DEPÓSITO'}>FAL DEPÓSITO</MenuItem>
<MenuItem value={'FAL EFL'}>FAL EFL</MenuItem>
<MenuItem value={'FAL ESTAÇÃO'}>FAL ESTAÇÃO</MenuItem>
<MenuItem value={'FAL ESTUFA'}>FAL ESTUFA</MenuItem>
<MenuItem value={'FAL GADO CORTE'}>FAL GADO CORTE</MenuItem>
<MenuItem value={'FAL GALPÃO EFL'}>FAL GALPÃO EFL</MenuItem>
<MenuItem value={'FAL LAB COMB'}>FAL LAB COMB</MenuItem>
<MenuItem value={'FAL LAB NUTRIÇÃO'}>FAL LAB NUTRIÇÃO</MenuItem>
<MenuItem value={'FAL MARCENARIA'}>FAL MARCENARIA</MenuItem>
<MenuItem value={'FAL MINHOCÁRIO'}>FAL MINHOCÁRIO</MenuItem>
<MenuItem value={'FAL OBSERVATÓRIO'}>FAL OBSERVATÓRIO</MenuItem>
<MenuItem value={'FAL SEDE'}>FAL SEDE</MenuItem>
<MenuItem value={'FAL VESTIÁRIO'}>FAL VESTIÁRIO</MenuItem>
<MenuItem value={'FAL VIV EFL'}>FAL VIV EFL</MenuItem>
<MenuItem value={'FAL VIV MACACOS'}>FAL VIV MACACOS</MenuItem>
<MenuItem value={'LAB SOLOS'}>LAB SOLOS</MenuItem>
<MenuItem value={'MANEJO OVINOS'}>MANEJO OVINOS</MenuItem>
<MenuItem value={'MAQ AGRÍCOLAS'}>MAQ AGRÍCOLAS</MenuItem>
<MenuItem value={'PRIMAT QUARENT'}>PRIMAT QUARENT</MenuItem>
<MenuItem value={'PRIMATOLOGIA'}>PRIMATOLOGIA</MenuItem>
<MenuItem value={'PROD FLOR.'}>PROD FLOR.</MenuItem>
            </TextField>
          </Grid>

    </div>
  )
}

export default GridAgua
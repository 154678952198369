import React from 'react';
import {
    MenuItem,
    TextField,
    Grid,
    Chip,
  } from "@mui/material";

function GridCei({handler}, {edificio}) {
  return (
    <div>
        <Grid item xs={3}>
            <TextField
              label="Edifício"
              variant="outlined"
              value={edificio}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handler}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                  </div>
                ),
              }}
            >
                <MenuItem  value={"FCE MESP"}>FCE MESP</MenuItem>
                <MenuItem  value={"FCE UAC"}>FCE UAC</MenuItem>
                <MenuItem  value={"FCE UED"}>FCE UED</MenuItem>
                <MenuItem  value={"FCE UEP"}>FCE UEP</MenuItem>
            </TextField>
          </Grid>

    </div>
  )
}

export default GridCei
import { Navigate, Route, Routes } from 'react-router-dom'
import Filtros from './components/Filtros';
import Impressao from './components/Impressao/Impressao';
import { useContext } from 'react';
import { IssueContext } from './contexts/IssueContext';
import React  from 'react';
export function Router() {

    const {issue, setIssue} = useContext(IssueContext)

    return (
        <Routes>
            <Route path='/' element={<Filtros />} />
            <Route path='/imprimir' element={<Impressao />} />
        </Routes>
    )
}
import React from 'react';
import {
    MenuItem,
    TextField,
    Grid,
    Chip,
  } from "@mui/material";

function GridDarcyA({handler}, {edificio}) {
  return (
    <div>
        <Grid item xs={3}>
            <TextField
              label="Edifício"
              variant="outlined"
              value={edificio}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handler}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                  </div>
                ),
              }}
            >
                <MenuItem value={'ALMOX'}>ALMOX</MenuItem>
<MenuItem value={'APÓS-FUB'}>APÓS-FUB</MenuItem>
<MenuItem value={'ARENA'}>ARENA</MenuItem>
<MenuItem value={'ASFUB'}>ASFUB</MenuItem>
<MenuItem value={'AUTOTRAC/PCTEC/UNBTV'}>AUTOTRAC/PCTEC/UNBTV</MenuItem>
<MenuItem value={'BAES'}>BAES</MenuItem>
<MenuItem value={'BCE'}>BCE</MenuItem>
<MenuItem value={'BSA NORTE'}>BSA NORTE</MenuItem>
<MenuItem value={'BSA SUL'}>BSA SUL</MenuItem>
<MenuItem value={'CAEP'}>CAEP</MenuItem>
<MenuItem value={'CASA DO PROF.'}>CASA DO PROF.</MenuItem>
<MenuItem value={'CASTELO'}>CASTELO</MenuItem>
<MenuItem value={'CCOMUN/CCAB'}>CCOMUN/CCAB</MenuItem>
<MenuItem value={'CCONV NEGRA'}>CCONV NEGRA</MenuItem>
<MenuItem value={'CDS'}>CDS</MenuItem>
<MenuItem value={'CDT'}>CDT</MenuItem>
<MenuItem value={'CEFTRU'}>CEFTRU</MenuItem>
<MenuItem value={'CESPE/CEAD'}>CESPE/CEAD</MenuItem>
<MenuItem value={'CET'}>CET</MenuItem>
<MenuItem value={'CIC/EST'}>CIC/EST</MenuItem>
<MenuItem value={'CME/DIMEQ/DIMAT'}>CME/DIMEQ/DIMAT</MenuItem>
<MenuItem value={'COLINA'}>COLINA</MenuItem>
<MenuItem value={'CONCHA'}>CONCHA</MenuItem>
<MenuItem value={'CPD/STI'}>CPD/STI</MenuItem>
<MenuItem value={'CRAD'}>CRAD</MenuItem>
<MenuItem value={'CVIV'}>CVIV</MenuItem>
<MenuItem value={'DEP. MAT. TÓX.'}>DEP. MAT. TÓX.</MenuItem>
<MenuItem value={'EFL'}>EFL</MenuItem>
<MenuItem value={'FACE'}>FACE</MenuItem>
<MenuItem value={'FD'}>FD</MenuItem>
<MenuItem value={'FE 1'}>FE 1</MenuItem>
<MenuItem value={'FE 3'}>FE 3</MenuItem>
<MenuItem value={'FE 5'}>FE 5</MenuItem>
<MenuItem value={'FESQ'}>FESQ</MenuItem>
<MenuItem value={'FS/FM'}>FS/FM</MenuItem>
<MenuItem value={'FS/FM ENTREPOSTO'}>FS/FM ENTREPOSTO</MenuItem>
<MenuItem value={'FT'}>FT</MenuItem>
<MenuItem value={'FUNDAR/BEIJODROMO'}>FUNDAR/BEIJODROMO</MenuItem>
<MenuItem value={'GARAGEM'}>GARAGEM</MenuItem>
<MenuItem value={'GEOCRONO'}>GEOCRONO</MenuItem>
<MenuItem value={'HVET'}>HVET</MenuItem>
<MenuItem value={'IB'}>IB</MenuItem>
<MenuItem value={'ICC CENTRO'}>ICC CENTRO</MenuItem>
<MenuItem value={'ICC NORTE'}>ICC NORTE</MenuItem>
<MenuItem value={'ICC SUL'}>ICC SUL</MenuItem>
<MenuItem value={'ICS'}>ICS</MenuItem>
<MenuItem value={'INFRALAB'}>INFRALAB</MenuItem>
<MenuItem value={'IPOL/IREL'}>IPOL/IREL</MenuItem>
<MenuItem value={'IQ'}>IQ</MenuItem>
<MenuItem value={'LAB J. E. DE PAULA'}>LAB J. E. DE PAULA</MenuItem>
<MenuItem value={'MALOCA'}>MALOCA</MenuItem>
<MenuItem value={'MAQUETE'}>MAQUETE</MenuItem>
<MenuItem value={'MARC. ESCOLA'}>MARC. ESCOLA</MenuItem>
<MenuItem value={'MARCENARIA'}>MARCENARIA</MenuItem>
<MenuItem value={'MARCENARIA II'}>MARCENARIA II</MenuItem>
<MenuItem value={'MASC CENTRO'}>MASC CENTRO</MenuItem>
<MenuItem value={'MASC NORTE'}>MASC NORTE</MenuItem>
<MenuItem value={'MASC SUL'}>MASC SUL</MenuItem>
<MenuItem value={'NMT'}>NMT</MenuItem>
<MenuItem value={'NMT ENTREPOSTO'}>NMT ENTREPOSTO</MenuItem>
<MenuItem value={'OCA II'}>OCA II</MenuItem>
<MenuItem value={'OES/IdA'}>OES/IdA</MenuItem>
<MenuItem value={'P ECOLÓGICO'}>P ECOLÓGICO</MenuItem>
<MenuItem value={'P POLICIAL'}>P POLICIAL</MenuItem>
<MenuItem value={'PAT'}>PAT</MenuItem>
<MenuItem value={'PIJ'}>PIJ</MenuItem>
<MenuItem value={'PJC'}>PJC</MenuItem>
<MenuItem value={'PMU I'}>PMU I</MenuItem>
<MenuItem value={'PMU II'}>PMU II</MenuItem>
<MenuItem value={'PRC'}>PRC</MenuItem>
<MenuItem value={'QUADRA DE ESPORTES'}>QUADRA DE ESPORTES</MenuItem>
<MenuItem value={'REITORIA'}>REITORIA</MenuItem>
<MenuItem value={'RU'}>RU</MenuItem>
<MenuItem value={'SG 1'}>SG 1</MenuItem>
<MenuItem value={'SG 10'}>SG 10</MenuItem>
<MenuItem value={'SG 11'}>SG 11</MenuItem>
<MenuItem value={'SG 12'}>SG 12</MenuItem>
<MenuItem value={'SG 2'}>SG 2</MenuItem>
<MenuItem value={'SG 4'}>SG 4</MenuItem>
<MenuItem value={'SG 8'}>SG 8</MenuItem>
<MenuItem value={'SG 9'}>SG 9</MenuItem>
<MenuItem value={'SIS'}>SIS</MenuItem>
<MenuItem value={'TECBOR'}>TECBOR</MenuItem>
<MenuItem value={'TERMOBIOLOGIA'}>TERMOBIOLOGIA</MenuItem>
<MenuItem value={'ULEG FS'}>ULEG FS</MenuItem>
<MenuItem value={'ULEG FT'}>ULEG FT</MenuItem>


            </TextField>
          </Grid>

    </div>
  )
}

export default GridDarcyA
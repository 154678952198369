import React from 'react';
import {
    MenuItem,
    TextField,
    Grid,
    Chip,
  } from "@mui/material";

function GridTorto({handler}, {edificio}) {
  return (
    <div>
        <Grid item xs={3}>
            <TextField
              label="Edifício"
              variant="outlined"
              value={edificio}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handler}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                  </div>
                ),
              }}
            >
                
                <MenuItem value={'CPAB 1'}>CPAB 1</MenuItem>
<MenuItem value={'CPAB 2'}>CPAB 2</MenuItem>
<MenuItem value={'HVETÃO 1'}>HVETÃO 1</MenuItem>
<MenuItem value={'HVETÃO 2'}>HVETÃO 2</MenuItem>
<MenuItem value={'HVETÃO 3'}>HVETÃO 3</MenuItem>

            </TextField>
          </Grid>

    </div>
  )
}

export default GridTorto
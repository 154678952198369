import {
  MenuItem,
  Paper,
  TextField,
  Grid,
  Chip,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { getFiltered } from "../api/api";
import TablePage from "./TablePage";
import GridAlto from "./Grid/GridAlto";
import GridAgua from "./Grid/GridAgua";
import GridCei from "./Grid/GridCei";
import GridDarcyA from "./Grid/GridDarcyA";
import GridDarcyB from "./Grid/GridDarcyB";
import GridDarcyC from "./Grid/GridDarcyC";
import GridGama from "./Grid/GridGama";
import GridPlanaltina from "./Grid/GridPlanaltina";
import GridTorto from "./Grid/GridTorto";
import Loading from "./Loading";
import Autocomplete from '@mui/material/Autocomplete';
import { IssueContext } from "../contexts/IssueContext";

function Filtros() {
  const {issue, setIssue} = useContext(IssueContext)

  const customers =[
    {label: 'gbruno@unb.br' },
    {label: 'confessor.confessor1@gmail.com' },
    {label: 'ronna88@gmail.com' },
    {label: 'fronzamirian@unb.br' },
    {label: 'wanderson@unb.br' },
    {label: 'anacbrs@unb.br' },
    {label: 'unbcliente@suxberger.com.br' },
    {label: 'jrmachado.engcivil@gmail.com' },
    {label: 'valdeci@unb.br' },
    {label: 'gabriel.tecmpe@gmail.com' },
    {label: 'vitorparanhos@unb.br' },
    {label: 'mpeengenharia.unb@gmail.com' },
    {label: 'jonas.eng.rodrigues@gmail.com' },
    {label: 'unbmpe@suxberger.com.br' },
    {label: 'marciolcn@unb.br' },
    {label: 'renatobarros@unb.br' },
    {label: 'eliasgf@unb.br' },
    {label: 'emergenciampe@gmail.com' },
    {label: 'nataliap.mpe@gmail.com' },
    {label: 'unbfiscal@suxberger.com.br' },
    {label: 'carolinaalmeida.engcivil@gmail.com' },
    {label: 'marciolisboa@unb.br' },
    {label: 'eltonsilvampe@gmail.com' },
    {label: 'elainehenrique@unb.br' },
    {label: 'danielle@unb.br' },
    {label: 'matheuskaminski@unb.br' },
    {label: 'plantaompeunb@gmail.com' },
    {label: 'cleitontorres@unb.br' },
    {label: 'williammagalhaesmpe@gmail.com' },
    {label: 'maico.limamatias@gmail.com' },
    {label: 'elonfilho@unb.br' },
    {label: 'theo@ronna.com.br' },
    {label: 'luizpaulotst2011@gmail.com' },
    {label: 'rogerio.olivares@unb.br' },
    {label: 'thayna.alves@unb.br' },
    {label: 'willkenhallisonmpe@gmail.com' },
    {label: 'tomjunior@unb.br' },
    {label: 'matheus.oliveira@mpeengenharia.com.br' },
    {label: 'thiagovales@unb.br' },
    {label: 'denis.vieira@mpeengenharia.com.br' },
    {label: 'thaynara.castro@unb.br' },
    {label: 'tauanr@unb.br' },
    {label: 'willkenshallisonmpe@gmail.com' },
    {label: 'gabriel@suxberger.com.br' },
    {label: 'luiz.eng.bsb@gmail.com' },
    {label: 'carloscarioka@gmail.com' },
    {label: 'cadu@unb.br' },
    {label: 'leandrocdt@unb.br' },
    {label: 'herick.mpe@gmail.com' },
    {label: 'augustodias@unb.br' },
    {label: 'carolinarocha.mpe@gmail.com' },
    {label: 'joaobarros@unb.br' },
    {label: 'dagolopes@unb.br' },
    {label: 'tiaoegg@hotmail.com' },
    {label: 'dimap@unb.br' },
    {label: 'mpalcantara@unb.br' },
    {label: 'engbiancamoreira@gmail.com' },
    {label: 'crispim@unb.br' },
    {label: 'engbianca.msilva@gmail.com' },
    {label: 'iagodacostasilva6@gmail.com' },
    {label: 'alexsandroarcelinompe@gmail.com' },
    {label: 'carloscarioka@unb.br' },
    {label: 'vidigal.barbosa@unb.br' },
    {label: 'humbertofarias@unb.br' },
    {label: 'fabricioferreirampe@gmail.com' },
    {label: 'costa.nataliap@gmail.com' },
    {label: 'alekez@unb.br' },
    {label: 'mapa.paraujo@gmail.com' },
    {label: 'gratao@unb.br' },
    {label: 'impermeabilizacaompe@gmail.com' },
    {label: 'juliana.iahn@unb.br' },
    {label: 'daniellamreng@gmail.com' },
    
  ]



  const [form, setForm] = useState({
    especialidade: [],
    issuetype: [],
    status: [],
    regiao: '',
    edificio: '',
    relator: ''
   
  });
  const [list, setList] = useState([]);

  const handleStatus = (event) => {
    setForm({ ...form, status: event.target.value });
  };
  const handleIssuetype = (event) => {
    setForm({ ...form, issuetype: event.target.value });
  };
  const handleEspecialidade = (event) => {
    setForm({ ...form, especialidade: event.target.value });
  };
  const handleRegiao = (event) => {
    setForm({ ...form, regiao: event.target.value });
  };
  const handleEdificio = (event) => {
    setForm({ ...form, edificio: event.target.value });
  };
  const handleRelator = (event, newValue) => {
    console.log(newValue.label);
    setForm({ ...form, relator: newValue.label });
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getFiltered(form)
    .then((result) => {
       setList(result.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    
    
  }, [form]);

  return (
    <>
      <Paper elevation={3}>
        <Grid
          className="esquerda"
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={3}>
            <TextField
              label="Especialidade"
              variant="outlined"
              value={form.especialidade}
              style={{ minWidth: 130 }}
              select
              onChange={handleEspecialidade}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} style={{ margin: 2 }} />
                    ))}
                  </div>
                ),
              }}
            >
              
              <MenuItem value={"Alvernaria"}>Alvenaria</MenuItem>
              <MenuItem value={"Elétrica"}>Elétrica</MenuItem>
              <MenuItem value={"Fibra Óptica"}>Fibra Óptica</MenuItem>
              <MenuItem value={"Hidráulica"}>Hidráulica</MenuItem>
              <MenuItem value={"Impermeabilização"}>Impermeabilização</MenuItem>
              <MenuItem value={"Limpeza e Conservação"}>Limpeza e Conservação</MenuItem>
              <MenuItem value={"Marcenaria"}>Marcenaria</MenuItem>
              <MenuItem value={"Pintura"}>Pintura</MenuItem>
              <MenuItem value={"Serralheria"}>Serralheria</MenuItem>
              <MenuItem value={"Telecomunicações"}>Telecomunicações</MenuItem>
              <MenuItem value={"Vidraçaria"}>Vidraçaria</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Tipo de OS"
              variant="outlined"
              value={form.issuetype}
              style={{ minWidth: 130 }}
              select
              onChange={handleIssuetype}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} style={{ margin: 2 }} />
                    ))}
                  </div>
                ),
              }}
            >
              <MenuItem value={"Corretiva"}>Corretiva</MenuItem>
              <MenuItem value={"Preventiva"}>Preventiva</MenuItem>
              <MenuItem value={"Adequação"}>Adequação</MenuItem>
              <MenuItem value={"Instalação"}>Instalação</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Status"
              variant="outlined"
              value={form.status}
              style={{ minWidth: 130 }}
              select
              onChange={handleStatus}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} style={{ margin: 2 }} />
                    ))}
                  </div>
                ),
              }}
            >
              <MenuItem value={"Aguardando Aprovação"}>Aguardando Aprovação</MenuItem>
              <MenuItem value={"Aguardando Aprovação Orçamentária"}>Aguardando Aprovação Orçamentária</MenuItem>
              <MenuItem value={"Aguardando Aprovação Orçamentária Após Serviço Executado"}>Aguardando Aprovação Orçamentária Após Serviço Executado</MenuItem>
              <MenuItem value={"Aguardando Envio à MPE"}>Aguardando Envio à MPE</MenuItem>
              <MenuItem value={"Aguardando Fiscalização"}>Aguardando Fiscalização</MenuItem>
              <MenuItem value={"Aguardando Início"}>Aguardando Início</MenuItem>
              <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
              <MenuItem value={"Em Orçamento/Projeto"}>Em Orçamento/Projeto</MenuItem>
              <MenuItem value={"Executado"}>Executado</MenuItem>
              <MenuItem value={"Imprevisto"}>Imprevisto</MenuItem>
              <MenuItem value={"Levantamento de Serviço"}>Levantamento de Serviço</MenuItem>
              <MenuItem value={"Não Executado"}>Não Executado</MenuItem>
              <MenuItem value={"Aberto"}>Aberto</MenuItem>
              <MenuItem value={"Orçamento Aprovado"}>Orçamento Aprovado</MenuItem>
              <MenuItem value={"Orçamento Em Revisão"}>Orçamento Em Revisão</MenuItem>
              <MenuItem value={"Pendente"}>Pendente</MenuItem>
              <MenuItem value={"Pendência Administrativa"}>Pendência Adminstrativa</MenuItem>
              <MenuItem value={"Pendência de Execução"}>Pendência de Execução</MenuItem>
              <MenuItem value={"Pendência Programada"}>Pendência Programada</MenuItem>
              <MenuItem value={"Pendência À Planejar"}>Pendência À Planejar</MenuItem>
              <MenuItem value={"Planejado"}>Planejado</MenuItem>
              <MenuItem value={"Realizado Parcialmente"}>Realizado Parcialmente</MenuItem>
              <MenuItem value={"Reprogramado"}>Reprogramado</MenuItem>
              <MenuItem value={"Serviço Finalizado"}>Serviço Finalizado</MenuItem>
              <MenuItem value={"Serviço Iniciado"}>Serviço Iniciado</MenuItem>
            </TextField>
          </Grid>

          <Autocomplete
            disablePortal
            id="relator"
            options={customers}
            sx={{ width: 300 }}
            
            onChange={handleRelator}
            renderInput={(params) => <TextField {...params} label="Relator"/>}
          />

          

          <Grid item xs={3}>
            <TextField
              label="Região"
              variant="outlined"
              value={form.complexo}
              style={{ minWidth: 130, maxWidth: 150 }}
              select
              onChange={handleRegiao}
              SelectProps={{
                multiple: false,
                renderValue: (selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    
                      <Chip key={selected} label={selected} style={{ margin: 2 }} />
                    
                  </div>
                ),
              }}
            >
              <MenuItem value={"ALTO PARAÍSO - GO"}>ALTO PARAÍSO - GO</MenuItem>
              <MenuItem value={"CEILÂNDIA"}>CEILÂNDIA</MenuItem>
              <MenuItem value={"DARCY GLEBA A"}>DARCY GLEBA A</MenuItem>
              <MenuItem value={"DARCY GLEBA B"}>DARCY GLEBA B</MenuItem>
              <MenuItem value={"DARCY GLEBA C"}>DARCY GLEBA C</MenuItem>
              <MenuItem value={"FAZENDA ÁGUA LIMPA"}>FAZENDA ÁGUA LIMPA</MenuItem>
              <MenuItem value={"GAMA"}>GAMA</MenuItem>
              <MenuItem value={"PLANALTINA"}>PLANALTINA</MenuItem>
              <MenuItem value={"TORTO"}>TORTO</MenuItem>
            </TextField>
          </Grid>

          
           {form.regiao ==='ALTO PARAÍSO - GO' && <GridAlto handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'CEILÂNDIA' && <GridCei handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'DARCY GLEBA A' && <GridDarcyA handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'DARCY GLEBA B' && <GridDarcyB handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'DARCY GLEBA C' && <GridDarcyC handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'FAZENDA ÁGUA LIMPA' && <GridAgua handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'GAMA' && <GridGama handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'PLANALTINA' && <GridPlanaltina handler={handleEdificio} edificio={form.edificio}/>}
           {form.regiao === 'TORTO' && <GridTorto handler={handleEdificio} edificio={form.edificio}/>}


        </Grid>
      </Paper>

      <br />
      <br />
      <br />
      <div className="center">
      { loading && <Loading />}
      
      </div>
      <TablePage dados={list} />
    </>
  );
  

}



export default Filtros;
